.swiper {
  display: flex;
  flex-wrap: wrap;
}
.logo_container {
 
  padding: 20px;
}
.logo_container img {
  width: 100%;
  max-height: 100px;
  box-shadow: 1px -1px 17px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 1px -1px 17px 0px rgba(177, 162, 162, 0.75);
  -moz-box-shadow: 1px -1px 17px 0px rgba(0,0,0,0.75);
}