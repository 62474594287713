.solution_identification {
margin-top: 15px;
}

.solution_identification h5 {
    margin-top: 15px;
   font-weight: bold;
}

.ri-check-double-line {
    color : red ;
}